import React from "react"
import { StaticQuery, graphql } from "gatsby"
import Image from "gatsby-image"
import Apps from "../../components/apps"
import AppHead from "../../components/apps/head"
import Section from "../../components/layouts/section"
import "./index.css"

export default ({ data, location }) => {
  return (
    <StaticQuery
      query={graphql`
        query NotfoundQuery {
          file(name: { eq: "notfound" }) {
            childImageSharp {
              fluid(maxWidth: 320) {
                ...GatsbyImageSharpFluid_withWebp_tracedSVG
              }
            }
          }
        }
      `}
      render={(data) => (
        <Apps>
          <AppHead
            pageTitle={"404"}
            pagePath={location.pathname}
            noIndex={true}
          />
          <Section padding="md">
            <Image
              className="notfound-image"
              fluid={data.file.childImageSharp.fluid}
              alt="Not Found!"
            />
          </Section>
        </Apps>
      )}
    />
  )
}
